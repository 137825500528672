import Banner from 'app/components/partials/banner';
import { clientEnv } from 'config/env';
import defaultBannerImage from 'static/images/default-page-banner.jpg';
import { ENDPOINTS } from 'config/api';
import ErrorPage from './error/not-found-page';
import fetch from '../../utilities/fetch';
import Helmet from 'react-helmet';
import Loader from '../partials/loader';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// eslint-disable-next-line require-jsdoc
function renderMessage(title, message) {
    return (
        <main role="main" className="main">
            <Helmet>
                <title>General Newsletter Unsubscribe</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="page || page-view">
                <Banner
                    title={title}
                    introduction={message}
                    images={[defaultBannerImage]}
                />
            </div>
        </main>
    );
}

// Unsubscribe for general newsletter
export default function NewsletterUnsubscribePage() { // eslint-disable-line require-jsdoc
    const [searchParams] = useSearchParams();
    const [unsubscribed, setUnsubscribed] = useState(null);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function() {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(clientEnv.RECAPTCHA_KEY, { action: 'newsletter_unsubscribe' }).then(async function(token) {
                try {
                    // 200 OK always means success
                    await fetch(ENDPOINTS.UNSUBSCRIBE, null, {
                        method: 'POST',
                        body: JSON.stringify({
                            email: searchParams.get('email'),
                            token: searchParams.get('token'),
                            captchaToken: token,
                        }),
                    });
                    setUnsubscribed(true);
                } catch (error) {
                    // Just display page not found if unsuccessful
                    setUnsubscribed(false);
                }
            });
        });
    }, []);

    if (unsubscribed === null) {
        // Awaiting unsubscribe API call result
        return (
            <div className="page || page-view">
                <div className="loader-container pad">
                    <Loader />
                </div>
            </div>
        );
    } else if (unsubscribed) {
        // Unsubscribe successful
        return renderMessage(
            'You have successfully unsubscribed',
            'You have unsubscribed from the Zoo news.'
        );
    }

    // Unsuccessful (user not found or other error)
    return <ErrorPage />;
}
