// Custom pages
export const FRONTEND_ROUTES = {
    STYLE_GUIDE: '/style-guide',
    DONATE_PAYMENT: '/get-involved/donate/payment',
    DONATE_THANK_YOU: '/get-involved/donate/thank-you',
    NEWSLETTER_UNSUBSCRIBE: '/newsletter/unsubscribe',
    // Portal auth
    PORTAL_LOGIN: '/members/login',
    PORTAL_FORGOT_PASSWORD: '/members/password/forgot',
    PORTAL_RESET_PASSWORD: '/members/password/reset',
    BUY_MEMBERSHIP: '/membership/buy',
    BUY_MEMBERSHIP_THANK_YOU: '/membership/buy/thank-you',
    GIFT_MEMBERSHIP: '/membership/gift',
    GIFT_MEMBERSHIP_THANK_YOU: '/membership/gift/thank-you',
    // needs authentication
    RENEW_MEMBERSHIP: '/membership/renew',
    RENEW_MEMBERSHIP_THANK_YOU: '/membership/renew/thank-you'
};

export const MEMBERS_PORTAL_ROUTES = {
    BASE: '/members/:slug',
    DASHBOARD: '/members/dashboard',
    MEMBER_DETAILS: '/members/details',
    RENEW: '/members/renew',
    GIFT: '/members/gift',
    REDEEM_GIFT: '/members/redeem-gift',
    ACCOUNT_SETTINGS: '/members/account'
};


export const isFrontendRoute = (pathname) => {
    const frontendRoutesArray = Object.values(FRONTEND_ROUTES);

    return frontendRoutesArray.includes(pathname);
};
