/* eslint-disable camelcase */
import CheckBox from 'app/components/partials/checkbox';
import { createValidationDataObject } from 'app/utilities/form-validation';
import Input from 'app/components/partials/input';
import Loader from 'app/components/partials/loader';
import PropTypes from 'prop-types';
import { PURCHASER_FORM_FIELDS } from 'config/buy-memberships-purchaser';
import Select from 'app/components/partials/select';
import { useSearchParams } from 'react-router-dom';
import { EVENT_ADD_SHIPPING_INFO, fireCartEvent, generatePassHoldersData } from 'app/utilities/buy-memberships';
import {
    pushCartAction,
    setPurchaserFieldValieAction,
    submitBookingAction,
    submitGiftPurchaseAction,
    touchedPurchaserFieldAction
} from 'app/ducks/buy-memberships';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Payment = ({ stepClickHandler, costsAfterDiscount, isGifting = false }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [status, setStatus] = useState();
    const dispatch = useDispatch();
    const {
        productsSelected,
        currentStepIndex,
        passHolders,
        primaryContact,
        purchaser,
        renewPassId,
        extraDiscount,
        // for gifting only
        gifter,
        giftReceiver
    } = useSelector((state) => state.buyMemberships);
    const { isAuth, user } = useSelector((state) => state.auth);

    const [searchParams] = useSearchParams();
    const cartToken = searchParams.get('cart');

    const setFieldValue = (fieldName, value) => dispatch(setPurchaserFieldValieAction(fieldName, value));
    const touchedField = (fieldName) => dispatch(touchedPurchaserFieldAction(fieldName));
    const getFieldTouched = (fieldName) => purchaser[fieldName].touched;
    const getFieldValue = (fieldName) => purchaser[fieldName].value;

    const autoFillPrimaryContactInfo = () => {
        if (isGifting) {
            setFieldValue('first_name', gifter.gifter_first_name.value);
            setFieldValue('last_name', gifter.gifter_last_name.value);
        } else if (primaryContact.first_name.value) {
            setFieldValue('first_name', primaryContact.first_name.value);
            setFieldValue('last_name', primaryContact.last_name.value);
        } else if (user) {
            // If primary contact is empty, try to retrieve from auth user
            // This situation happens if user tries to renew a pass which only has child pass holders
            setFieldValue('first_name', user.first_name);
            setFieldValue('last_name', user.last_name);
        }
    };

    const checkIsFormInvalid = () => {
        const invalid = PURCHASER_FORM_FIELDS.some(({ name, validator }) => {
            const value = getFieldValue(name);

            return !validator(value).valid;
        }, []);

        return invalid;
    };

    const generatePurchaser = () => {
        return PURCHASER_FORM_FIELDS.reduce((acc, { name }) => {
            acc[name] = getFieldValue(name);

            return acc;
        }, {});
    };

    // For gift purchase
    const generateProductQtys = () => {
        return productsSelected.reduce((acc, { product, quantities }) => {
            // Exclude guest as that is sent separately
            if (product.toLowerCase() !== 'adult guest') {
                acc[product] = quantities;
            }

            return acc;
        }, {});
    };

    const generateContactData = (contact) => {
        const data = {};

        for (const field in contact) {
            data[field] = contact[field].value;
        }

        return data;
    };

    const dispatchGiftPurchaseAction = () => {
        const generatedPurchaser = generatePurchaser();
        const productQtys = generateProductQtys();

        dispatch(submitGiftPurchaseAction({
            ...generateContactData(gifter),
            ...generateContactData(giftReceiver),
            product_qtys: productQtys,
            has_guest_pass: productsSelected.some(({ product }) => product.toLowerCase() === ('adult guest')),
            purchaser: generatedPurchaser,
            total_amount: costsAfterDiscount
        }, setIsSubmitting, setStatus));
    };

    // Data for purchase order and abandoned cart
    const generateOrderData = () => {
        const generatedPassHoldersData = generatePassHoldersData(passHolders);
        const generatedPurchaser = generatePurchaser();

        return {
            pass_holders: generatedPassHoldersData,
            purchaser: generatedPurchaser,
            has_guest_pass: productsSelected.some(({ product }) => product.toLowerCase() === ('adult guest')),
            requires_caregiver:  productsSelected.some(({ product }) => product.toLowerCase() === ('support person')),
            total_amount: costsAfterDiscount,
            renew_pass_id: renewPassId,
            ...extraDiscount && extraDiscount.ga && extraDiscount.ga.code ?
                { ga_promo_code: extraDiscount.ga.code } : {},
            cart_token: cartToken,
        };
    };

    const dispatchBookingAction = () => {
        dispatch(submitBookingAction(generateOrderData(), setIsSubmitting, setStatus));
    };

    const submitForm = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setStatus();

        // Push add shipping info event to data layer
        fireCartEvent(EVENT_ADD_SHIPPING_INFO, productsSelected, costsAfterDiscount, renewPassId, isGifting);

        if (isGifting) {
            dispatchGiftPurchaseAction();
        } else {
            dispatchBookingAction();
        }
    };

    const renderInput = (field) => {
        const { name, label, placeholder, isRequired, type, options, validator, inputType } = field;

        const touched = getFieldTouched(name);
        const value = getFieldValue(name);
        const validationData = touched ? validator(value) : createValidationDataObject();

        const fieldData = {
            key: name,
            id: name,
            name,
            label,
            type,
            placeholder,
            value,
            onChangeHandler: (event) => {
                setFieldValue(name, event.target.value);
                touchedField(name);
            },
            onBlurHandler: () => touchedField(name),
            options,
            isRequired,
            error: validationData.valid ? '' : validationData.message
        };

        if (type === 'select') {
            return <Select {...fieldData} />;
        }

        if (type === 'checkbox') {
            return <CheckBox key={name} inputType={inputType} label={label} value={value} onChangeHandler={(event) => {
                setFieldValue(name, event.target.checked);
                touchedField(name);
            }
            }/>;
        }

        return (
            <Input { ...fieldData } />
        );
    };

    useEffect(() => {
        // When buy memberships, only auth user can submit order
        if (!isGifting && !isAuth) stepClickHandler(currentStepIndex - 1);
    }, [isAuth]);

    useEffect(() => {
        autoFillPrimaryContactInfo();

        // Push current cart so that backend can track abandoned carts
        if (!isGifting) {
            dispatch(pushCartAction(generateOrderData()));
        }
    }, []);


    return (
        <div className="inner">
            <div className="constrain-width medium no-pad">
                <form className="billing-details-form" onSubmit={submitForm}>
                    <div className="section">
                        <div className="form-group space-between">
                            {PURCHASER_FORM_FIELDS.map((FIELD) => renderInput(FIELD))}
                        </div>
                    </div>
                    {status && status.error && status.error.message && <div className="error-message">{status.error.message}</div>}
                </form>
                <section className="section button-group">
                    <button
                        className="button"
                        onClick={() => stepClickHandler(currentStepIndex - 1)}
                    >Back</button>
                    <button
                        className="button primary"
                        onClick={submitForm}
                        disabled={isSubmitting || checkIsFormInvalid()}
                    >
                        {isSubmitting ? <Loader type="small" /> : 'Pay now'}
                    </button>
                </section>
            </div>
        </div>
    );
};

Payment.propTypes = {
    stepClickHandler: PropTypes.func.isRequired,
    costsAfterDiscount: PropTypes.string.isRequired,
    isGifting: PropTypes.bool
};

export default Payment;
